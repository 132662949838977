import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap-v5';
import { BsHeartPulse, BsBook, BsGraphUpArrow, BsCheck } from 'react-icons/bs';
import './KeyStats.css';

const statisticsData = [
  {
    percentage: 'Healthcare',
    text: [
      'InsightXR provides high-fidelity, immersive VR training modules for industries like healthcare.',
      'This allows healthcare professionals to practice procedures and protocols in a safe and controlled environment without the risk of harming real patients.',
      "Additionally, InsightXR's multi-user collaboration feature enables healthcare teams to train together in the same virtual environment, improving communication and teamwork skills.",
    ],
    icon: BsHeartPulse,
  },
  {
    percentage: 'Education',
    text: [
      "InsightXR gives today's learners and trainers next-generation educational tools.",
      "The platform's device management capabilities allow educators to easily distribute and manage VR/AR content to students.",
      'The customisation options allow educators to create tailored learning experiences that meet the specific needs of their students.',
    ],
    icon: BsBook,
  },
  {
    percentage: 'Training',
    text: [
      'InsightXR allows for the creation of immersive training experiences designed for high-risk industries.',
      'The platform boasts a 75% increase in knowledge retention, a 90% increase in engagement with XR training, and a 275% increase in confidence for trainees.',
      "InsightXR's performance assessment and skill retention features allow trainers to track student progress and identify areas where they need additional support.",
      'The platform can also help to reduce training costs by eliminating the need for expensive equipment and travel.',
    ],
    icon: BsGraphUpArrow,
  },
];

const KeyStats = () => {
  return (
    <div
      className='mxr__section insightSection'
      style={{ backgroundColor: '#f8faff' }}
    >
      <Container className='mt-5 mb-5'>
        <h4 className='text-center mxr__section-title'>
          benefits of InsightXR for various industries
        </h4>
        {/* <h4 className='text-center mxr__section-title'>
          What are the key features and benefits of InsightXR for various
          industries like healthcare, education, and training?
        </h4> */}
        <p className='text-center'>
          InsightXR is a comprehensive management platform for VR and AR devices
          that streamlines the deployment of XR at scale. The platform offers a
          number of features and benefits for various industries, including
          healthcare, education, and training.
        </p>
        <Row className='mt-4'>
          {statisticsData.map((stat, index) => (
            <Col md={4} sm={6} xs={12} key={index} className='mb-4'>
              <Card className='text-center shadow InsightFIrstCard'>
                <Card.Body className='d-flex flex-column align-items-center'>
                  <div className='icon-container'>
                    <stat.icon
                      className='statInsightIcon'
                      style={{ color: '#1292ee', fontSize: '3rem' }}
                    />
                  </div>
                  <Card.Title>{stat.percentage}</Card.Title>
                  {/* Render each paragraph inside the text array */}
                  <div className='text-content'>
                    {stat.text.map((paragraph, idx) => (
                      <p key={idx} className='text-start'>
                        <BsCheck
                          style={{
                            marginRight: '8px',
                            color: '#1292ee',
                            fontSize: '1.5rem',
                          }}
                        />
                        {paragraph}
                      </p>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default KeyStats;

// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap-v5';
// import { BsHeartPulse, BsBook, BsGraphUpArrow, BsCheck } from 'react-icons/bs';

// const statisticsData = [
//   {
//     percentage: 'Healthcare',
//     text: [
//       'InsightXR provides high-fidelity, immersive VR training modules for industries like healthcare.',
//       'This allows healthcare professionals to practice procedures and protocols in a safe and controlled environment without the risk of harming real patients.',
//       "Additionally, InsightXR's multi-user collaboration feature enables healthcare teams to train together in the same virtual environment, improving communication and teamwork skills.",
//     ],
//     icon: BsHeartPulse,
//   },
//   {
//     percentage: 'Education',
//     text: [
//       "InsightXR gives today's learners and trainers next-generation educational tools.",
//       "The platform's device management capabilities allow educators to easily distribute and manage VR/AR content to students.",
//       'The customisation options allow educators to create tailored learning experiences that meet the specific needs of their students.',
//     ],
//     icon: BsBook,
//   },
//   {
//     percentage: 'Training',
//     text: [
//       'InsightXR allows for the creation of immersive training experiences designed for high-risk industries.',
//       'The platform boasts a 75% increase in knowledge retention, a 90% increase in engagement with XR training, and a 275% increase in confidence for trainees.',
//       "InsightXR's performance assessment and skill retention features allow trainers to track student progress and identify areas where they need additional support.",
//       'The platform can also help to reduce training costs by eliminating the need for expensive equipment and travel.',
//     ],
//     icon: BsGraphUpArrow,
//   },
// ];

// const KeyStats = () => {
//   return (
//     <div
//       className='mxr__section insightSection'
//       style={{ backgroundColor: '#f8faff' }}
//     >
//       <Container className='mt-5 mb-5'>
//         <h4 className='text-center mxr__section-title'>
//           {' '}
//           What are the key features and benefits of InsightXR for various
//           industries like healthcare, education, and training?
//         </h4>
//         <p className=''>
//           <strong>
//             InsightXR is a comprehensive management platform for VR and AR
//             devices that streamlines the deployment of XR at scale. The platform
//             offers a number of features and benefits for various industries,
//             including healthcare, education, and training.
//           </strong>
//         </p>
//         <Row>
//           <Col className='d-flex justify-content-md-center gap-5'>
//             {statisticsData.map((stat, index) => (
//               <div className='stat-item InsightStat' key={index}>
//                 {/* Render the icon component */}
//                 <stat.icon
//                   className='mb-4 statInsightIcon'
//                   style={{ color: '#1292ee', fontSize: '4rem' }}
//                 />
//                 <h3>{stat.percentage}</h3>
//                 {/* Render each paragraph inside the text array */}
//                 {stat.text.map((paragraph, idx) => (
//                   <p key={idx}>
//                     <BsCheck
//                       style={{
//                         marginRight: '8px',
//                         color: '#1292ee',
//                         fontSize: '2rem',
//                       }}
//                     />

//                     {paragraph}
//                   </p>
//                 ))}
//               </div>
//             ))}
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default KeyStats;

// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap-v5';
// import { RiGraduationCapFill } from 'react-icons/ri';
// import { FaThumbsUp } from 'react-icons/fa';
// import { FaFaceGrinWide } from 'react-icons/fa6';

// const statisticsData = [
//   {
//     percentage: '75%',
//     text: 'Increase in knowledge retention',
//     icon: RiGraduationCapFill,
//   },
//   {
//     percentage: '90%',
//     text: 'Increase in engagement with XR training',
//     icon: FaThumbsUp,
//   },
//   {
//     percentage: '275%',
//     text: 'Increase in confidence',
//     icon: FaFaceGrinWide,
//   },
// ];

// const KeyStats = () => {
//   return (
//     <div
//       className='mxr__section insightSection'
//       style={{ backgroundColor: '#f8faff' }}
//     >
//       <Container className='mt-5 mb-5'>
//         <Row>
//           <Col className='d-flex justify-content-md-center gap-5'>
//             {statisticsData.map((stat, index) => (
//               <div
//                 className='stat-item InsightStat
//               '
//                 key={index}
//               >
//                 {/* Render the icon component directly */}
//                 <stat.icon
//                   className='mb-4 statInsightIcon'
//                   style={{ color: '#1292ee', fontSize: '4rem' }}
//                 />
//                 <h3>{stat.percentage}</h3>
//                 <p>{stat.text}</p>
//               </div>
//             ))}
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default KeyStats;
