import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import Bloglist from '../../blogsdata.json';
import ShareButton from 'react-social-share-buttons';
import { Container, Row, Col } from 'react-bootstrap-v5';
import './blog.css';
import DOMPurify from 'dompurify';

function Blogs() {
  let { slug } = useParams();

  useEffect(() => {}, [slug]);

  const blogPost = Bloglist.find((list) => list.slug === slug);

  if (!blogPost) {
    return <div>Blog not found!</div>;
  }
  return (
    <>
      <div>
        {Bloglist.map((list) => {
          if (list.slug === slug) {
            return (
              <React.Fragment key={list.id}>
                <Helmet>
                  <title>{list.metatag}</title>
                </Helmet>
                <div className='banner-container'>
                  <img
                    src={require(`../../assets/images/Innerbanner/${list.bannerImg}`)}
                    alt='Banner'
                    className='banner-image'
                  />
                  <div className='banner-text'>
                    <h1 className='blogsHeading'> {list.bannerHeading}</h1>

                    <p className='bannerSubheading mt-1'>{list.subHeading}</p>
                  </div>
                </div>
                <Container>
                  <Row className='justify-content-md-center mt-5'>
                    {/* <img
                      src='https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg'
                      alt=''
                    /> */}
                    <Col>
                      <h1 className='blogPara'>{list.title}</h1>
                      {list.body.map((data, index) => {
                        if (data.type === 'para') {
                          return data.content.map((txt, pindex) => {
                            const sanitizedHtml = DOMPurify.sanitize(txt);
                            const htmlWithLinks = sanitizedHtml.replace(
                              /<a href="(.*?)"/g,
                              '<a href="$1" target="_blank" rel="noopener noreferrer"'
                            );
                            return (
                              <p
                                key={`${index}-${pindex}`}
                                // key={pindex}
                                dangerouslySetInnerHTML={{
                                  __html: htmlWithLinks,
                                }}
                              ></p>
                            );
                          });
                          // return data.content.map((txt, pindex) => {
                          //   return <p key={pindex}>{txt}</p>;
                          // });
                        } else if (data.type === 'title') {
                          if (data.paraImg) {
                            return (
                              <>
                                <React.Fragment key={index}>
                                  <img
                                    src={require(`../../assets/images/${data.paraImg}`)}
                                    alt='Banner'
                                    className='para-img'
                                  />
                                  {/*  heading  title*/}
                                  <h2>{data.content}</h2>
                                </React.Fragment>
                                {/* <img
                                  src={require(`../../assets/images/${data.paraImg}`)}
                                  alt='Banner'
                                  className='para-img'
                                />
                                <h3 key={index}>{data.content}</h3> */}
                              </>
                            );
                          } else {
                            return <h2 key={index}>{data.content}</h2>;
                          }
                        } else if (data.type === 'list') {
                          return (
                            <ul key={index}>
                              {data.content.map((lis, lindex) => {
                                // return <li key={lindex}>{lis}</li>;
                                return (
                                  <h3
                                    className='blogH3Tag'
                                    style={{ color: '#777' }}
                                    key={`${index}-${lindex}`}
                                  >
                                    {lis}
                                  </h3>
                                );
                              })}
                            </ul>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Col>
                  </Row>
                </Container>
              </React.Fragment>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div>
        <Row className='bottm_bar'>
          <Col md={6}>
            <h4 className='mt-4'>Share The {blogPost.slug} Post!</h4>
            {/* <p>
              By <NavLink>admin</NavLink> | September 05th, 2024 |{' '}
              <NavLink>blogs</NavLink> | <NavLink>0 Comments</NavLink>{' '}
            </p> */}
          </Col>
          <Col md={6} className='social_links_bottom'>
            <ShareButton
              compact
              socialMedia={'facebook'}
              url={'https://xkcd.com/1024/'}
              media={'https://imgs.xkcd.com/comics/error_code.png'}
              text='Sit by a lake'
            />
            <ShareButton
              compact
              socialMedia={'twitter'}
              url={'https://xkcd.com/1024/'}
              media={'https://imgs.xkcd.com/comics/error_code.png'}
              text='Sit by a lake'
            />
            <ShareButton
              compact
              socialMedia={'google-plus'}
              url={'https://xkcd.com/1024/'}
              media={'https://imgs.xkcd.com/comics/error_code.png'}
              text='Sit by a lake'
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Blogs;
