// import React, { useRef } from 'react';
// import { Container, Carousel } from 'react-bootstrap-v5';
// import './trusted.css';
// // import nhs from '../../assets/icons/about-us-icons/nhs.png';
// import nhs from '../../assets/icons/about-us-icons/nhs3.jpeg';
// // import citb from '../../assets/icons/about-us-icons/citb.png';
// import mod from '../../assets/icons/about-us-icons/mod.png';
// import skillhub from '../../assets/icons/about-us-icons/skillHub.png';
// import hillingdon from '../../assets/icons/about-us-icons/hillingdon.png';
// import andover from '../../assets/icons/about-us-icons/andover.png';
// import bae from '../../assets/icons/about-us-icons/bae.png';
// import british from '../../assets/icons/about-us-icons/britishgas2.jpg';

// const TrustedBy = () => {
//   const nodeRef = useRef(null);

//   return (
//     <div className='mxr__section'>
//       <Container>
//         <div className='trusted-by'>
//           <div className='trusted-by-header'>
//             <hr />
//             <h2 className='mxr__section-title'>Our UK-Trusted Partners</h2>
//             <hr />
//           </div>
//           <Carousel interval={3000} ref={nodeRef}>
//             <Carousel.Item className='trustedItem'>
//               <div className='trusted-by-logos'>
//                 <img className='trustLogos nhs' src={nhs} alt='nhs' />
//                 {/* <img className='trustLogos' src={citb} alt='citb' /> */}
//                 <img className='trustLogos mod' src={mod} alt='mod' />
//                 <img
//                   className='trustLogos skill'
//                   src={skillhub}
//                   alt='skillhub'
//                 />
//                 <img
//                   className='trustLogos hilli'
//                   src={hillingdon}
//                   alt='hillingdon'
//                 />
//               </div>
//             </Carousel.Item>
//             <Carousel.Item>
//               <div className='trusted-by-logos'>
//                 <img
//                   className='trustLogos andover'
//                   src={andover}
//                   alt='andover'
//                 />
//                 <img className='trustLogos bae' src={bae} alt='bae' />
//                 <img
//                   className='trustLogos british'
//                   src={british}
//                   alt='british'
//                 />
//               </div>
//             </Carousel.Item>
//           </Carousel>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default TrustedBy;

import React from 'react';
import { Container } from 'react-bootstrap-v5';
import './trusted.css';
// import nhs from '../../assets/icons/about-us-icons/nhs.png';
import nhs from '../../assets/icons/about-us-icons/nhs3.jpeg';
// import citb from '../../assets/icons/about-us-icons/citb.png';
import mod from '../../assets/icons/about-us-icons/mod.png';
import skillhub from '../../assets/icons/about-us-icons/skillHub.png';
import hillingdon from '../../assets/icons/about-us-icons/hillingdon.png';
import andover from '../../assets/icons/about-us-icons/andover.png';
import bae from '../../assets/icons/about-us-icons/bae.png';
import british from '../../assets/icons/about-us-icons/britishgas2.jpg';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const TrustedBy = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    // pauseOnHover: true,
    nextArrow: (
      <FaChevronRight
        size={30}
        className='custom-arrow  '
        style={{ color: '#1292ee' }}
      />
    ),
    prevArrow: (
      <FaChevronLeft
        size={30}
        className='custom-arrow '
        style={{ color: '#1292ee' }}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // const nodeRef = useRef(null);
  const partners = [
    { src: nhs, alt: 'NHS' },
    { src: mod, alt: 'MOD' },
    { src: skillhub, alt: 'SkillHub' },
    { src: hillingdon, alt: 'Hillingdon' },
    { src: andover, alt: 'Andover' },
    { src: bae, alt: 'BAE' },
    { src: british, alt: 'British Gas' },
  ];
  return (
    <div className='mxr__section'>
      <Container>
        <div className='trusted-by'>
          <div className='trusted-by-header'>
            <hr />
            <h2 className='mxr__section-title'>Our UK-Trusted Partners</h2>
            <hr />
          </div>
          <Slider {...settings} className='custom-carousel trustedSlide mx-4'>
            {partners.map((partner, index) => (
              <div key={index} className='text-center trustedDiv'>
                <img
                  src={partner.src}
                  alt={partner.alt}
                  className='trustLogos'
                  style={{
                    width: '150px',
                    height: '150px',
                    objectFit: 'contain',
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default TrustedBy;
