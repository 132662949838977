import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
// import immersive from '../../assets/icons/stats/ImmersiveTraining.svg';
// import Tracking from '../../assets/icons/stats/Performance-Tracking.svg';
// import Engagement from '../../assets/icons/stats/Increased-Engagement.svg';
// import PostTraning from '../../assets/icons/stats/Post-traning.svg';
// import UserRecording from '../../assets/icons/stats/UserRecording.svg';
import InsightImg from '../../assets/images/insightXrApp.png';
// import './styles.css';
import {
  FaVrCardboard,
  FaUsers,
  FaClipboardList,
  FaVideo,
  FaChartLine,
} from 'react-icons/fa';

// import {
//   MdDevices,
//   MdScale,
//   MdContentCopy,
//     MdAddComment,
// } from 'react-icons/md';
// import {
//   MdSchool,
//   MdOutlineAssessment,
//   //   MdReview,
//   MdReplayCircleFilled,
// } from 'react-icons/md';

const InsightXROverview = () => {
  return (
    <Container>
      <section className='impact mxr__section'>
        <h2 className='text-center my-4 mxr__section-title'>
          How does InsightXR improve knowledge retention and user engagement
          compared to traditional training methods?
        </h2>
        <p>
          InsightXR leverages immersive training to improve knowledge retention
          and user engagement compared to traditional training methods. Learners
          often forget 70% of training content within 24 hours and almost 90%
          within a month. However, InsightXR reports a 75% increase in knowledge
          retention and a 90% increase in user engagement with XR training.
        </p>
        <p>
          The platform achieves these improvements through several key features:
        </p>
        <Row>
          <Col>
            <div className='container my-5'>
              <div className='row row-cols-1 row-cols-md-2 g-4'>
                <div className='col'>
                  <div className='card h-100'>
                    <div className='card-body'>
                      <div className='icon-container text-center mb-3'>
                        <FaVrCardboard
                          size={50}
                          color='#1292ee'
                          className='IconsHover'
                        />
                      </div>
                      <h5 className='card-title text-center '>
                        Immersive Training & Simulations
                      </h5>
                      <p className='card-text'>
                        InsightXR provides high-fidelity, immersive VR training
                        modules for industries like healthcare, construction,
                        and manufacturing. This immersive approach allows users
                        to engage with the training content more directly and
                        experientially, leading to better knowledge retention.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='card h-100'>
                    <div className='card-body'>
                      <div className='icon-container text-center mb-3'>
                        <FaUsers
                          size={50}
                          color='#1292ee'
                          className='IconsHover'
                        />
                      </div>
                      <h5 className='card-title text-center'>
                        Multi-user Collaboration
                      </h5>
                      <p className='card-text'>
                        InsightXR supports multi-user collaboration within the
                        same virtual environment, enhancing teamwork and
                        problem-solving skills. This interactive element can
                        increase user engagement and make the training process
                        more enjoyable.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='card h-100'>
                    <div className='card-body'>
                      <div className='icon-container text-center mb-3'>
                        <FaChartLine
                          size={50}
                          color='#1292ee'
                          className='IconsHover'
                        />
                      </div>
                      <h5 className='card-title text-center'>
                        Performance Assessment & Skill Retention
                      </h5>
                      <p className='card-text '>
                        The platform tracks and assesses user competency with
                        detailed performance analytics, ensuring effective
                        training outcomes and long-term skill retention. By
                        monitoring progress and providing feedback, InsightXR
                        helps users retain information and improve their skills
                        over time.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='card h-100'>
                    <div className='card-body'>
                      <div className='icon-container text-center mb-3'>
                        <FaClipboardList
                          size={50}
                          color='#1292ee'
                          className='IconsHover'
                        />
                      </div>
                      <h5 className='card-title text-center'>
                        Debriefing Scenario
                      </h5>
                      <p className='card-text'>
                        InsightXR includes debriefing tools to facilitate
                        post-simulation discussions, allowing participants to
                        review their actions, understand their decisions, and
                        reinforce learning outcomes. This structured reflection
                        process can deepen understanding and improve knowledge
                        retention.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className='col'>
              <div
                className='card h-100 mt-5
              '
              >
                <div className='card-body'>
                  <div className='icon-container text-center mb-3'>
                    <FaVideo size={50} color='#1292ee' className='IconsHover' />
                  </div>
                  <h4 className='card-title text-center'>
                    Recording User Interactions
                  </h4>
                  <p className='card-text'>
                    The ability to record user interactions within scenarios
                    allows for a detailed review and analysis of user behaviour,
                    decisions, and learning outcomes. This data can provide
                    valuable insights into the effectiveness of the training and
                    identify areas for improvement.
                  </p>
                </div>
              </div>
            </div>
            <img className='InsightKnowledgeImg' src={InsightImg} alt='' />
          </Col>
        </Row>

        <p>
          <strong>
            By combining immersive experiences, collaborative opportunities,
            performance tracking, and debriefing tools, InsightXR provides a
            more engaging and effective learning environment compared to
            traditional training methods, leading to improved knowledge
            retention and user engagement.
          </strong>
        </p>
      </section>
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* <section className='device-management mxr__section'>
        <h2>
          What are the key features of InsightXR's platform that allow for the
          efficient management of XR devices at scale?
        </h2>
        <section className='platform-features py-5'>
          <div className='container'>
            <h4 className='mb-4'>
              Here are the key features of InsightXR's platform that allow for
              the efficient management of XR devices at scale, based on the
              sources provided:
            </h4>

            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>
                  <MdDevices
                    size={50}
                    color='#1292ee'
                    className='me-2 IconsHover'
                  />
                  Enterprise Device Management
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    This feature enables organisations to manage and remotely
                    control their VR/AR devices from a central location. This
                    includes essential management tasks like app distribution,
                    file management, and tracking device health.
                  </p>
                  <p>
                    Administrators can configure devices, distribute content,
                    and monitor the status of their XR fleet, which is crucial
                    for managing large deployments efficiently. For instance,
                    they can track device statuses, installed content, Wi-Fi
                    connection, and location, and even control devices in
                    real-time with remote commands.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='1'>
                <Accordion.Header>
                  <MdScale
                    size={50}
                    color='#1292ee'
                    className='me-2 IconsHover'
                  />
                  Scalability
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    InsightXR is designed to support the deployment of XR
                    technology on a large scale across multiple locations. This
                    is essential for organisations with distributed teams, or
                    those running complex training programmes that require the
                    management of potentially thousands of devices.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='2'>
                <Accordion.Header>
                  <MdContentCopy
                    size={50}
                    color='#1292ee'
                    className='me-2 IconsHover'
                  />
                  Content Management & Delivery
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    InsightXR enables the creation, distribution, and management
                    of VR/AR content across a variety of devices. This
                    centralised approach simplifies the process of deploying and
                    updating XR experiences across numerous devices, ensuring
                    that all users can access the latest training materials or
                    applications.
                  </p>
                  <p>
                    <strong> For example:- </strong> Organisations can use
                    InsightXR to deploy libraries of apps, files, videos, and
                    webXR links, and configure devices with bundles of XR
                    content, firmware, and settings. Additionally,
                    administrators can remotely manage content versions and
                    updates.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </section>
      </section> */}
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* <div className='mxr__section '>
        <Container>
          <img src={InsightPhoto} alt='' />
        </Container>
      </div> */}
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* <section className='knowledge-retention py-5 mxr__section'>
        <div className='container'>
          <h2 className='mb-4'>
            How does InsightXR address the common challenge of poor knowledge
            retention in training programs?
          </h2>
          <h4>InsightXR's Approach to Knowledge Retention</h4>
          <p className='mb-5'>
            InsightXR tackles the issue of poor knowledge retention, a common
            problem in training programs where learners often forget a
            significant portion of the content quickly, by using immersive
            training. Here's how InsightXR improves knowledge retention:
          </p>

          <div className='row align-items-center mb-4'>
            <div className='col-md-6'>
              <h4>
                <strong>Immersive Training</strong>
              </h4>
              <p>
                InsightXR focuses on immersive VR training, especially for
                high-risk industries. This hands-on experience in a safe virtual
                environment helps learners grasp concepts better than
                traditional methods, leading to better knowledge retention.
              </p>
            </div>
            <div className='col-md-6 text-center'>
              <img className='insightAddressIcon' src={immersive} alt='' />
            </div>
          </div>

          <div className='row align-items-center mb-4'>
            <div className='col-md-6 order-md-2'>
              <h4>
                <strong>Increased Engagement</strong>
              </h4>
              <p>
                InsightXR reports a significant 75% increase in knowledge
                retention and a 90% boost in user engagement with its XR
                training. The platform's immersive and interactive nature
                contributes to this improvement.
              </p>
            </div>
            <div className='col-md-6 order-md-1 text-center'>
              <img className='insightAddressIcon' src={Engagement} alt='' />
            </div>
          </div>

          <div className='row align-items-center mb-4'>
            <div className='col-md-6'>
              <h4>
                <strong>Performance Tracking and Feedback</strong>
              </h4>
              <p>
                InsightXR's "Performance Assessment & Skill Retention" feature
                plays a crucial role. By tracking user competency and offering
                detailed performance analytics, it ensures effective training
                outcomes and promotes long-term skill retention. This constant
                feedback loop helps learners identify areas for improvement and
                reinforces their learning.
              </p>
            </div>
            <div className='col-md-6 text-center'>
              {' '}
              <img className='insightAddressIcon' src={Tracking} alt='' />
            </div>
          </div>

          <div className='row align-items-center mb-4'>
            <div className='col-md-6 order-md-2'>
              <h4>
                <strong>Post-Training Review</strong>
              </h4>
              <p>
                The platform incorporates "Debriefing Scenario" tools to
                encourage discussions after simulations. This allows
                participants to analyse their actions, understand their
                decisions, and solidify their understanding of the training
                content.
              </p>
            </div>
            <div className='col-md-6 order-md-1 text-center'>
              <img
                className='insightAddressIcon'
                src={PostTraning}
                alt='PostTraning'
              />
            </div>
          </div>

          <div className='row align-items-center'>
            <div className='col-md-6'>
              <h4>
                <strong>User Interaction Recording</strong>
              </h4>
              <p>
                InsightXR can record user interactions during scenarios. By
                reviewing these recordings, trainers can gain insights into user
                behaviour, decision-making processes, and overall learning
                outcomes. This data helps tailor training programs for better
                effectiveness and address specific areas where knowledge
                retention might be weaker.
              </p>
            </div>
            <div className='col-md-6 text-center'>
              <img
                className='insightAddressIcon'
                src={UserRecording}
                alt='User Recording'
              />
            </div>
          </div>
        </div>
      </section> */}
      {/* this site is made by kuldeep */}
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* <section className='knowledge-retention py-5'>
        <div className='container'>
          <h2 className='mb-4'>
            How does InsightXR's platform address the challenge of learners
            forgetting training content?
          </h2>
          <p>InsightXR: Combating Knowledge Loss in Training</p>
          <p>
            The InsightXR platform tackles the pervasive issue of knowledge
            retention in training by using a multi-pronged approach that
            leverages immersive technology and data-driven insights.
          </p>

          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <MdSchool
                  size={50}
                  color='#1292ee'
                  className='me-2 IconsHover'
                />
                Immersive and Engaging VR Training Experiences
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  One of the key ways InsightXR addresses this challenge is by
                  shifting from traditional, passive learning methods to
                  immersive and engaging VR training experiences, especially for
                  high-risk industries. This immersive approach, facilitated by
                  high-fidelity VR training modules, allows learners to actively
                  participate in realistic simulations, leading to better
                  comprehension and knowledge retention.
                </p>
                <p>
                  Instead of simply reading or hearing about procedures,
                  learners can practice them firsthand in a safe, virtual
                  environment, which mirrors the benefits of hands-on training.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='1'>
              <Accordion.Header>
                <MdReplayCircleFilled
                  size={50}
                  color='#1292ee'
                  className='me-2 IconsHover'
                />
                Boost in User Engagement and Retention
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Furthermore, InsightXR boosts user engagement, a crucial
                  factor in knowledge retention. The platform boasts a 90%
                  increase in engagement with XR training compared to
                  traditional methods. This heightened engagement stems from the
                  immersive nature of the platform and its ability to create
                  more captivating and interactive learning experiences.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='2'>
              <Accordion.Header>
                <MdOutlineAssessment
                  size={50}
                  color='#1292ee'
                  className='me-2 IconsHover'
                />
                Performance Assessment & Skill Retention
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Beyond the immersive training itself, InsightXR provides tools
                  to reinforce learning and track progress. The platform's
                  "Performance Assessment & Skill Retention" feature monitors
                  user competency and delivers detailed performance analytics,
                  allowing trainers to identify areas where learners may be
                  struggling and provide targeted support.
                </p>
                <p>
                  This data-driven approach ensures effective training outcomes
                  and promotes long-term skill retention.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='3'>
              <Accordion.Header>
                <MdAddComment
                  size={50}
                  color='#1292ee'
                  className='me-2 IconsHover'
                />
                Post-Training Review & Reflection
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Additionally, InsightXR encourages post-training review and
                  reflection through its "Debriefing Scenario" tools. These
                  tools facilitate post-simulation discussions, allowing
                  participants to review their actions, analyse their decisions,
                  and solidify their understanding of the training content.
                </p>
                <p>
                  This structured debriefing process helps reinforce learning
                  and improve knowledge retention by encouraging active
                  reflection on the training experience.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='4'>
              <Accordion.Header>
                <MdReplayCircleFilled
                  size={50}
                  color='#1292ee'
                  className='me-2 IconsHover'
                />
                User Interaction Recording for Tailored Training
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Finally, InsightXR's ability to record user interactions
                  during training scenarios provides valuable data that can be
                  used to further enhance knowledge retention. By analyzing
                  these recordings, trainers can gain insights into user
                  behaviour, decision-making processes, and overall learning
                  outcomes.
                </p>
                <p>
                  This data can be used to identify specific areas where
                  knowledge retention is weak and tailor training programmes
                  accordingly, ensuring that the training content is effectively
                  absorbed and retained by learners.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section> */}
    </Container>
  );
};

export default InsightXROverview;
